// React
import React from 'react'

// MUI
import { CssBaseline } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const DarkModeContext = React.createContext({
    darkMode: true,
    toggleMode: () => { }
})

function ThemeContext(props) {

    const [darkMode, setDarkMode] = React.useState(true)
    function toggleMode() {
        setDarkMode(!darkMode)
    }

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <DarkModeContext.Provider value={{ darkMode, toggleMode }}>
                {props.children}
            </DarkModeContext.Provider>
        </ThemeProvider>
    )
}

export default DarkModeContext
export {
    ThemeContext
} 