// React
import React from 'react'

// MUI
import { Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material'

// local storage
import useLocalStorage from '@rehooks/local-storage'

// custom
import DarkModeContext from '../context/ThemeContext'

function DarkModeSwitch(props) {
    const {darkMode, toggleMode} = React.useContext(DarkModeContext)
    return (
        <Switch
            checked={darkMode}
            onChange={toggleMode}
        />
    )
}

function SettingsDialog(props) {
    const {open, close} = props

    const [unitOfMeasure, setUnitofMeasure] = useLocalStorage("unitOfMeasure", "inches")

    return (
        <Dialog
            open={open}
            onClose={close}
            scroll="body"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle align="center">
                Settings
            </DialogTitle>
            <DialogContent>
                <Stack
                    spacing={1}
                    sx={{marginTop: 1}}
                >
                    <FormControl
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel id="unit-label">Unit of Measure</InputLabel>
                        <Select
                            labelId="unit-label"
                            value={unitOfMeasure}
                            onChange={event => setUnitofMeasure(event.target.value)}
                            label="Unit of Measure"
                        >
                            <MenuItem value="inches">Inches</MenuItem>
                            <MenuItem value="m">Meters</MenuItem>
                            <MenuItem value="mm">Milimeters</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        value="Dark Mode"
                        label="Dark Mode"
                        labelPlacement='start'
                        control={<DarkModeSwitch />}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default SettingsDialog