import db from './db'
import blows from './blows'
import projects from './projects'

const api = {
    db,
    projects,
    blows
}

export default api